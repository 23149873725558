import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import f from '../../utils/fetch';

import UpdateLostPasswordForm, { UpdateLostPasswordFields } from '../../components/Forms/UpdateLostPasswordForm'
import validateUpdateLostPasswordForm from '../../components/Forms/UpdateLostPasswordForm/validate'
import useQueryParam from '../../hooks/useQueryParam'
import routes from '../../routes'
import Alert from '../../components/Alert'




const UpdateLostPassword: React.FC = () => {

    const [error, setError] = useState<string | null>(null);
    let [email] = useQueryParam<string | undefined>('email')
    const navigate = useNavigate()



    useEffect(() => {
        if (!email) {
            navigate(routes.HOME)
        }
    }, [email])

    const onSubmit = async (values: UpdateLostPasswordFields) => {
        try {



            const r: any = await f({ url: `/users/update-lost-password`, method: 'POST', data: { ...values, ...{ email } } });

            navigate({
                pathname: routes.HOME,
                search: window.location.search,
            });

            navigate(routes.HOME)
        } catch (error: any) {
            setError(error.message)
        }
    }


    return (
        <div className='w-full lg:w-4/12 px-4'>
            <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0'>
                <div className="rounded-t mb-0 px-6 py-6">

                    <div style={{ fontSize: 60, textAlign: 'center', fontWeight: 900, fontFamily: 'Nunito' }} className="text-blueGray-600">OMEO</div>
                    <div className="text-blueGray-400 text-center mb-3 font-bold"><small>Mettez à jour votre mot de passe</small></div>



                    <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    {error && <Alert color='danger'>{error}</Alert>}

                    <Formik
                        initialValues={{ code: '', password: '', passwordConfirm: '' }}
                        onSubmit={onSubmit}
                        component={UpdateLostPasswordForm}
                        validate={validateUpdateLostPasswordForm}
                    />
                    <div className="text-center">
                        <Link to={routes.LOST_PASSWORD}>

                            <small>Lancer une nouvelle procédure</small>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
                <div className="w-1/2">
                    <Link to={{ pathname: routes.HOME, search: window.location.search }} className="text-blueGray-200">

                        <small>S'identifier </small>
                    </Link>
                </div>
            </div>

        </div>
    )

}

export default UpdateLostPassword
